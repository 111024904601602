import React from 'react'
import { Link } from 'gatsby'
import ViewNieuws from "../Views/viewNieuws";
import Article from "../Article/article";


export default ({children, title, subtitle, fieldParagraphOverzicht, image, introLink}) => {
  const viewDerivative = fieldParagraphOverzicht.viewDerivative;

  return(
    <React.Fragment>
      <div id="intro" className="intro">
        <div className="grid-container">
          <div className="grid-x">
            <div className="cell medium-5">
              <div className="intro__blog">
                {viewDerivative.results.length === 0 &&
                  <React.Fragment>
                    <h1 className="intro__blog-title">
                      {title}
                    </h1>
                    <h5
                      className="intro__blog-subtitle"
                      dangerouslySetInnerHTML={{__html: subtitle.hasOwnProperty('processed') ? subtitle.processed : null }}
                    />
                    <p>
                      <Link
                        to={introLink.url.path}
                        className={"intro__blog-button button secondary"}
                      >
                        {introLink.title}
                      </Link>
                    </p>
                  </React.Fragment>
                }
                {viewDerivative.results.length > 0 &&
                  <ViewNieuws>
                      <Article
                          articleData={viewDerivative.results[0]}
                          type={'intro'}
                      />
                  </ViewNieuws>
                }
              </div>
            </div>
            <div className="cell medium-7">
              <div className="intro__logo">
                <img src={image.url} height="541" className="" alt={title}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
