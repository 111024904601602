import React from "react"
import PropTypes from "prop-types"
import WebFormField from './WebFormField'
import { useForm, FormContext } from 'react-hook-form'
import { buildYup } from "json-schema-to-yup"

const WebForm = ({ formFields, children, uuid, state, closedMessage, onSubmit }) => {

  let validationMessages = {errMessages: {}}
  let validationSchema = {type: "object",properties: {},required: []}
  const typeMapping = {
    'text': 'string',
    'textarea': 'string',
    'tel': 'string',
    'checkbox': 'boolean',
    'email': 'string'
  }
  const renderForm = (formFields) => {

    validationSchema.properties['name'] = {
      description: 'name',
      type: 'string',
      required: true
    }
    validationSchema.required.push('name')
    validationMessages.errMessages['name'] = {
      required: 'Gelieve uw naam in te vullen'
    }

    validationSchema.properties['email'] = {
      description: 'email',
      type: 'string',
      required: true
    }
    validationSchema.required.push('email')
    validationMessages.errMessages['email'] = {
      required: 'Gelieve uw email in te vullen'
    }

    validationSchema.properties['message'] = {
      description: 'message',
      type: 'string',
      required: true
    }
    validationSchema.required.push('message')
    validationMessages.errMessages['message'] = {
      required: 'Gelieve uw boodschap in te vullen'
    }


    validationSchema.properties['privacy'] = {
      description: 'privacy',
      type: 'boolean',
      required: true
    }
    validationSchema.required.push('privacy')
    validationMessages.errMessages['privacy'] = {
      oneOf: 'Gelieve het privacybeleid te accepteren'
    }

    /* append captcha field validation */
    validationSchema.properties['captcha'] = {
      description: 'captcha',
      type: 'string',
      required: true
    }
    validationSchema.required.push('captcha')
    validationMessages.errMessages['captcha'] = {
      required: 'Gelieve captcha te vervolledigen'
    }

    const hookFormMethods = useForm({
      mode: 'onBlur',
      reValidateMode: 'onChange',
      validationSchema: buildYup(validationSchema, validationMessages),
      validateCriteriaMode: "all",
      submitFocusError: true,
      nativeValidation: false,
    })

      return (
        <FormContext {...hookFormMethods}>
            <form onSubmit={ hookFormMethods.handleSubmit(onSubmit) } style={{marginTop: '-70px'}}>
              <div className={"grid-x grid-margin-x"}>
                  <WebFormField
                    key={"name"}
                    name={"name"}
                    placeholder={"Uw naam"}
                    label={"Uw naam"}
                    type={"text"}
                    className={"cell"}
                  />
                  <WebFormField
                    key={"email"}
                    name={"email"}
                    placeholder={"Uw email"}
                    label={"Uw email"}
                    type={"email"}
                    className={"cell"}
                  />
                  <WebFormField
                    key={"message"}
                    name={"message"}
                    placeholder={"Uw bericht"}
                    label={"Uw bericht"}
                    type={"textarea"}
                    className={"cell"}
                  />
                  <WebFormField
                    key={"privacy"}
                    name={"privacy"}
                    placeholder={""}
                    label={"Ik ga akkoord met <a href=\"/privacybeleid\" target=\"_blank\">pivacy & GDPR voorwaarden</a>."}
                    type={"checkbox"}
                    className={"cell"}
                  />
                {
                <WebFormField
                  key={"captcha"}
                  name={"captcha"}
                  type={"captcha"}
                />
                }
                {children}
                <fieldset className="cell">
                  <button className="button secondary" type="submit" style={{marginTop: '1rem'}}>
                    Verzenden
                  </button>
                </fieldset>
              </div>
          </form>
        </FormContext>
      )
    }

  return (
    <React.Fragment>
      {state === 'Open' && renderForm(formFields)}
      {state !== 'Open' &&
        <div
          className='form__closed'
          dangerouslySetInnerHTML={{__html: closedMessage}}
        />
      }
    </React.Fragment>
  )
}

WebForm.defaultProps = {
  closedMessage : 'Dit formulier is momenteel gesloten, probeer op een andere keer opnieuw. '
}

WebForm.propTypes = {
  formFields: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
}

export default WebForm
