import React from 'react'
import PropTypes from 'prop-types'
import Slick from "react-slick";
import Markdown from 'react-markdown'

const sliderOptions = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
}

const ViewReferentie = ({children, results}) => (
    <React.Fragment>
        <Slick
            {...sliderOptions}
        >
        {
            results.map((referentie, index) => {
                return(
                    <article className="testimonial" key={index}>
                        <div className="grid-x align-middle testimonial__content">
                            <div className="large-4 medium-12 column show-for-large profile-pic">
                                <img src={referentie.fieldImage.url} alt={referentie.title} />
                            </div>
                            <div className="large-8 medium-12 column testimonial__text">
                                <Markdown
                                    className="testimonial__quotation"
                                    source={referentie.body.processed}
                                    escapeHtml={false}
                                />
                                <div className="testimonial-slide-author-container">
                                    <div className="small-profile-pic hide-for-large">
                                        <img src={referentie.fieldImage.url} alt={referentie.title} />
                                    </div>
                                    <p className="testimonial__author-info">
                                        {referentie.title}<br/>
                                        <i className="subheader" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </article>
                )
            })
        }
        </Slick>
    </React.Fragment>
)

ViewReferentie.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ]),
    results: PropTypes.array.isRequired
}

export default ViewReferentie
