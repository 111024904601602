import  React from 'react'


const sectionHeader = ({ children, title, subtitle}) => (
    <div className="section__header">
        {
            title && <div
                className="section__title"
                dangerouslySetInnerHTML={{__html:title}}
            />
        }
        {

            subtitle && <div
                className="section__subtitle"
                dangerouslySetInnerHTML={{__html:subtitle.hasOwnProperty('processed') ? subtitle.processed : null }}
            />
        }
    </div>
)

export default sectionHeader;