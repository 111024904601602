import React from 'react'

export default ({children, results}) => (
  <div className="grid grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2 large-up-4">
    {
      results.map((viewResult, key) => {
        return (
          <div
            className="cell"
            key={key}
            itemScope
            itemType="http://schema.org/Product"
          >
            <div className="card card--service">
              <div className="card__image">
                <img className="" alt={viewResult.title} src={viewResult.fieldImage.url} itemProp="image"/>
              </div>
              <div className="card-section card__title">
                <h4 itemProp="name">{viewResult.title}</h4>
              </div>
              <div
                  className="card-section card__description"
                  itemProp="description"
                  dangerouslySetInnerHTML={{__html: (viewResult.body.processed) ? viewResult.body.processed : viewResult.body.summaryProcessed}}
              />
            </div>
          </div>
        )
      })
    }
  </div>
)
