import React from 'react'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'

import Layout from '../components/Layout/layout'
import BreadCrumb from '../components/Layout/breadCrumb'
import NodeTitle from '../components/Node/node_title'
import Markdown from 'react-markdown'
import Paragraphs from '../components/Paragraphs'
import AdminPanel from '../components/Admin'
import { sanitizeUrl } from '../helpers/'


export default (props) => {

    const page = props.pageContext.page;
    const breadcrumb = page.entityUrl.breadcrumb;
    const preview = props.preview;

    const mainClasses = classNames(
        "node",
    );

    return(
        <React.Fragment>
            <Helmet>
                <title>{page.title}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <meta name="MobileOptimized" content="width"/>
                {page.entityMetatags.map((metatag,key) => {
                    if(metatag.__typename === 'Default_MetaProperty'){
                        return <meta key={key} property={metatag.key} content={sanitizeUrl(metatag.value,'cms.')}/>
                    }else if(metatag.__typename === 'Default_MetaLink'){
                        return <link key={key} rel={metatag.key} href={sanitizeUrl(metatag.value,'cms.')}/>
                    }else{
                        return <meta key={key} name={metatag.key} content={sanitizeUrl(metatag.value,'cms.')}/>
                    }
                })}
            </Helmet>
            <Layout
                classNameMain={mainClasses}
            >
                {
                    (page.title.length > 0 && (page.entityUrl.path !== '/home' && page.entityUrl.path !== '/home-test')) &&
                      <NodeTitle
                          title={page.title}
                      />
                }
                    { page.body !== null &&
                        <section className="node__body">
                            <BreadCrumb
                                breadcrumb={breadcrumb}
                                container
                            />
                            {preview &&
                              <AdminPanel
                                adminUrl={'https://cms.sjverzekeringen.be/node/'}
                                nodeId={page.entityId}
                              />
                            }
                            <Markdown
                              className="grid-container"
                              escapeHtml={false}
                              source={page.body.processed}
                            />
                        </section>
                    }
                    { page.fieldParagraphs.length > 0 &&
                        <Paragraphs
                          className="paragraph__blokken"
                          paragraphs={page.fieldParagraphs}
                        />
                    }
            </Layout>
        </React.Fragment>
    )
}
