import React from 'react'
import Article from "../Article/article";
import ClassNames from 'classnames'

export default ({children,results,top}) => {

    const gridClassNames = ClassNames('grid','grid-x','grid-margin-x','grid-margin-y','small-up-1','medium-up-2',{
        'large-up-4': (results && results.length > 3),
        'large-up-3': (results && results.length === 3),
        'large-up-2': (results && results.length === 2),
    })
    return (
        <div>
            {results && (
                <div
                    className={gridClassNames}
                >
                    {
                        results.map((viewResult, key) => {
                            return (
                                <div
                                    className="cell"
                                    key={key}
                                >
                                    <Article
                                        articleData={viewResult}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            )}
            {children}
        </div>
    )
}
