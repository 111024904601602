import React from 'react'
import SectionHeader from '../Layout/section_header'
import ViewNieuws from '../Views/viewNieuws'
import ViewPartners from '../Views/viewPartners'
import ViewDiensten from '../Views/viewDiensten'


export default ({title, subtitle, fieldParagraphOverzicht, classes, paragraphID}) => {
  const viewDerivative = fieldParagraphOverzicht.viewDerivative;
  const typeName = viewDerivative.__typename;

  return(
    <React.Fragment>
        {(typeName === 'Default_NieuwsQueryResult' || typeName === 'Default_AllNieuwsQueryResult' ) &&
        <section className={`section ${classes}`} id={paragraphID}>
            <div className="grid-container">
              <SectionHeader
                title={title}
                subtitle={subtitle}
              />
                <div className="section__body">
                  <ViewNieuws
                    results={viewDerivative.results}
                  />
                </div>
            </div>
        </section>
        }
        {typeName === 'Default_DienstenQueryResult' &&
        <section className={`section ${classes}`} id={paragraphID}>
            <div className="grid-container">
              <SectionHeader
                title={title}
                subtitle={subtitle.hasOwnProperty('processed') ? subtitle.processes : null }
              />
                <div className="section__body">
                  <ViewDiensten
                    results={viewDerivative.results}
                  />
                </div>
            </div>
        </section>
        }
        {typeName === 'Default_PartnersQueryResult' &&
        <section className={`section ${classes}`} id={paragraphID}>
            <div className="grid-container">
              <SectionHeader
                 title={title}
                 subtitle={subtitle}
              />
                <div className="section__body">
                  <ViewPartners
                    results={viewDerivative.results}
                  />
                </div>
            </div>
        </section>
        }
    </React.Fragment>
  )
}
