import React, { useState } from 'react'
import SectionHeader from "../Layout/section_header";
import Webform from '../Webform';
import { gql } from "apollo-boost"
import { client } from '../../apollo/client'

const ParagraphWebform = ({ title, subtitle, webform, image }) => {

  const [formSubmitted, setFormSubmitted] = useState(false);

  const submitForm = async(values,e) => {

    if(values){
      const formId = "contact";
      //let mutationResult = {}

      if(values.hasOwnProperty('captcha')){
        delete(values.captcha)
      }

      const mutationInput = {
        values: JSON.stringify(values),
        webformId: formId
      }

      try{
        await client
        .mutate({
          mutation: gql`
            mutation webformSubmit($input: WebFormSubmitInput) {
              webformSubmit(input: $input)
            }
          `,
          variables: {
            input: mutationInput,
          },
        })
        .then(result => {
          e.target.reset();
          setFormSubmitted(true)
        })
      }catch(error){
        console.log('error occured')
      }
    }
  }

  return(
    <section className="section section--skew_left-top bgcolor-gray contact" id="contact">
      <div className="grid-container">
          <SectionHeader
              title={title}
              subtitle={subtitle}
          />
          <div className="section__content">
              <div className="grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2">
                  <div className="cell">
                      <div className="contact__icon">
                          <img src={image.url} align="Contact" alt={title}/>
                      </div>
                  </div>
                  <div className="cell">
                      {(!formSubmitted) ?
                          <Webform
                              formFields={webform.webformDerivative.elements}
                              uuid={'contact'}
                              state={'Open'}
                              onSubmit={submitForm}
                          />
                          /*<div></div>*/
                          :
                          <div className='form__submitted'>
                            Bericht verzonden.
                          </div>
                      }
                  </div>
              </div>
          </div>
      </div>
    </section>
  )
}

export default ParagraphWebform
