import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { useFormContext, Controller, ErrorMessage } from 'react-hook-form'
import ReCAPTCHA  from 'react-google-recaptcha'

const WebFormField = ({name, type, label, placeholder, options, className, formContext }) => {

    const { register, control, errors, setValue } = useFormContext();

    switch (type) {
        case "text":
        case "checkbox":
        case "email":
        case "tel":
          return (
            <fieldset key={name} className={className}>
              {type !== "checkbox" && (
                <label
                  htmlFor={name}
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              )}
              <input
                type={type}
                name={name}
                placeholder={placeholder}
                ref={register}
              />
              {type === "checkbox" && (
                <label
                  htmlFor={name}
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              )}
              <ErrorMessage errors={errors} name={name} />
            </fieldset>
          )
        case "select":
          return  (
            <fieldset key={name} className={className}>
              <label
                htmlFor={name}
                dangerouslySetInnerHTML={{ __html: label }}
              />
              <Controller
                as={<Select isMulti options={options} />}
                name={name}
                control={control}
                onChange={([selected]) => {
                  return { value: selected };
                }}
                defaultValue={{}}
              />
              <ErrorMessage errors={errors} name={name} />
            </fieldset>
          )
        case "textarea":
          return  (
            <fieldset key={name} className={className}>
              <label
                htmlFor={name}
                dangerouslySetInnerHTML={{ __html: label }}
              />
              <textarea
                ref={ register }
                name={ name }
                placeholder={ placeholder }
              />
              <ErrorMessage errors={errors} name={name} />
            </fieldset>
          )
        case "captcha":
          return  (
            <fieldset key={name} className={'cell'}>
              <Controller
                as={<ReCAPTCHA />}
                name={name}
                sitekey="6Lc-8tkUAAAAABY7QDriycn9DuHQzX1jkpDbI-f8"
                control={control}
                onChange={(recaptchaToken) => setValue(name, recaptchaToken, true)}
              />
              <ErrorMessage errors={errors} name={name} />
            </fieldset>
          )
        default:
          return null
      }
}

WebFormField.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string, 
    options: PropTypes.object
}

export default WebFormField