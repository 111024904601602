import React from 'react'
import { OutboundLink } from "gatsby-plugin-google-analytics";

export default ({children, results}) => (
  <div
    className="grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2 large-up-3"
  >
    {
      results.map((viewResult, key) => {
        return (
          <div
            className="cell partners__icon"
            key={key}
          >
            <OutboundLink
              href={viewResult.fieldLinkNaarPartner.url.path}
              target="_blank" rel="noopener noreferrer"
            >
              <img
                src={viewResult.fieldLogo.entity.fieldMediaImage.url}
                alt={viewResult.title}/>
            </OutboundLink>
          </div>
        )
      })
    }
  </div>
)

