import ApolloClient from "apollo-boost";
import {IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import { InMemoryCache } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';
import fetch from 'isomorphic-fetch';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});
const cache = new InMemoryCache({ fragmentMatcher });
export const client = new ApolloClient({
    cache,
    uri: "https://cms.sjverzekeringen.be/graphql",
    fetch
});
