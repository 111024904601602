import React from 'react'
import PropTypes from 'prop-types'

import ParagraphOverzicht from "./paragraphOverzicht";
import ParagraphIntro from "./paragraphIntro";
import ParagraphWebform from "./paragraphWebform";
import ParagraphCarousel from "./paragraphCarousel";

const Paragraph = ({ paragraphs, children, className }) => {
  return(
    <div className={className}>
      {
        paragraphs.map((paragraph, index) => {
          const typename = paragraph.entity.__typename;
          const title = (paragraph.entity.hasOwnProperty('fieldParagraphIntroTitel')) ? paragraph.entity.fieldParagraphIntroTitel : null;
          const subtitle = (paragraph.entity.hasOwnProperty('fieldParagraphIntroSubtitel')) ? paragraph.entity.fieldParagraphIntroSubtitel : null;
          const image  = (paragraph.entity.hasOwnProperty('fieldParagraphImage')) ? paragraph.entity.fieldParagraphImage : null;
          const webform = (paragraph.entity.hasOwnProperty('fieldParagraphWebform')) ? paragraph.entity.fieldParagraphWebform : null;
          const extraClasses = (paragraph.entity.hasOwnProperty('fieldParagrpahsClasses')) ? paragraph.entity.fieldParagrpahsClasses: null;
          const paragraphID = (paragraph.entity.hasOwnProperty('fieldParagraphId')) ? paragraph.entity.fieldParagraphId : null;

          if (typename === 'Default_ParagraphParagraphOverzicht') {
            return <ParagraphOverzicht
              title={title}
              subtitle={subtitle}
              fieldParagraphOverzicht={paragraph.entity.fieldParagraphOverzicht}
              classes={extraClasses}
              key={index}
              paragraphID={paragraphID}
            />
          }
          if(typename === 'Default_ParagraphParagraphIntro') {
            return <ParagraphIntro
              title={title}
              subtitle={subtitle}
              fieldParagraphOverzicht={paragraph.entity.fieldParagraphOverzicht}
              key={index}
              image={image}
              introLink={paragraph.entity.fieldParagraphIntroLink}
            />
          }
          if(typename === 'Default_ParagraphParagraphWebform'){
            return <ParagraphWebform
              title={title}
              subtitle={subtitle}
              image={image}
              webform={webform}
              key={index}
            />
          }
          if(typename === 'Default_ParagraphParagraphCarousel'){
            return <ParagraphCarousel
              title={title}
              fieldParagraphOverzicht={paragraph.entity.fieldParagraphOverzicht}
              key={index}
            />
          }
          return <div/>
        })
      }
      {children}
    </div>
  )
}

Paragraph.propTypes = {
  paragraphs: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  className: PropTypes.string
}

export default Paragraph
