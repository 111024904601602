import React from 'react'
import ViewReferenties from '../Views/viewReferenties'

export default ({children, fieldParagraphOverzicht}) => {
    const viewDerivative = fieldParagraphOverzicht.viewDerivative;
    return(
        <section className="section--skew_right-both  bgcolor-primary section paragraph--paragraph__carousel" id="referenties">
            <div className="grid-container">
                <div className="testimonial__slider">
                    <ViewReferenties
                        results={viewDerivative.results}
                    />
                </div>
            </div>
        </section>
    )
}

